import React from 'react';

export interface DirProps {
    dirList: DirList[],
    isDelete?: boolean,
    onDeleteChange(): void
}

export interface DirList {
    directory: string
    files: FileProps[],
    isDelete?: boolean,
    onDeleteChange(): void
}

export interface FileProps {
    fileName: string,
    fileSize: number,
    lastModified: string,
    link: string
}

export class Directory extends React.Component<DirProps> {
    render() {
        const { dirList, isDelete, onDeleteChange } = this.props;
        return (
            dirList.map(d =>
                <FileList key={d.directory} directory={d.directory} files={d.files} isDelete={isDelete} onDeleteChange={onDeleteChange} />)
        )}
}

export class FileList extends React.Component<DirList> {
    getFileSize(size: number) {
        return (
            size > 1000000000 ? (Math.ceil(Math.round((size / 1000000000) * 1000)) / 1000) + ' GB' :
                size > 1000000 ? (Math.ceil(Math.round((size / 1000000) * 1000)) / 1000) + ' MB' :
                    size > 1000 ? (Math.ceil(Math.round((size / 1000) * 1000)) / 1000) + ' KB' :
                        (Math.ceil(Math.round((size) * 1000)) / 1000) + ' B'
        )
    }

    renderFileDetail(file: FileProps) {
        return (
            <div className="filedetail">
                <div className="badge text-muted">
                    <span className="last-modified">{file.lastModified}</span>
                </div>
                <span className="badge badge-secondary">
                    {this.getFileSize(file.fileSize)}
                </span>
            </div>
        )
    }

    getTargetAttribute(fileName: string): string {
        let imageExt = new RegExp('^.*.(jpg|JPG|jpeg|JPEG|gif|GIF|png|PNG|webp|WEBP)$');
        if (imageExt.test(fileName)) {
            return '_blank';
        } else {
            return '_self';
        }
    }

    renderFileList(files: FileProps[]) {
        const { isDelete, onDeleteChange } = this.props;

        return (
            files.map((f: FileProps) =>
                <div key={f.fileName} className="card">
                    <div className="item">

                        {!isDelete &&
                            <>
                                <a href={f.link} target={ this.getTargetAttribute(f.fileName) } rel="noreferrer"><code>{f.fileName}</code></a>
                                <hr/>
                                { this.renderFileDetail(f) }
                            </>
                        }

                        {isDelete &&
                            <>
                                <input type="checkbox" className="todelete large green" value={f.link} onChange={onDeleteChange} />
                                <code>{f.fileName}</code>
                                <hr/>
                                { this.renderFileDetail(f) }
                            </>
                        }
                    </div>
                </div>
            )
        )
    }

    render() {
        const { directory, files } = this.props;
        return ( 
            <>
            {
                files.length > 0 &&
                <>
                    <h4 key={directory}>{directory === '' ? '/' : directory} 
                        <span className="text-muted">&nbsp;({files.length} {files.length === 1 ? 'item' : 'items'})</span>
                    </h4>
                    <div className="list">
                        { this.renderFileList(files) }
                    </div>
                </>
            }
            </>
        )
    }
}
