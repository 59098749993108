import React from 'react';
import '../App.css';

interface PageProps {
    className?: string
    children: JSX.Element[]
}

function Content(props: PageProps) {
    const componentStyle = { 'padding': '0.8rem' };

    return (
        <div className={props.className} style={componentStyle}>
            {props.children}
        </div>
    )
}

export default Content;