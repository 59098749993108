import { useEffect, useRef, useState } from 'react';
import ReactModal from 'react-modal';
// import logo from './logo.svg';
import './App.css';
import { Directory, DirList } from './components/FileList';
import Content from './components/Content';

let App = () => {
    const [dirList, setDirList] = useState([] as DirList[]);
    const [isDelete, setIsDelete] = useState(false);
    const [deleteListSize, setDeleteListSize] = useState(0);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const [isTelegram, setIsTelegram] = useState(false);
    const [isPrivate, setIsPrivate] = useState(false);

    let filesRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        getFiles();
    }, [])

    let getFiles = () => {
        let options = {
            method: 'GET'
        }
        fetch('api/files', options)
            .then(res => res.json())
            .then((data: DirList[]) => {
                setDirList([...data]);
            })
            .catch(err => console.error(err));
    }

    let uploadFiles = () => {
        let filesDOM = filesRef?.current?.files as FileList

        if (filesDOM !== undefined) {
            let formData = new FormData();
            for (let i = 0; i < filesDOM.length; i++) {
                formData.append('files', filesDOM[i]);
            }

            formData.append('telegram', isTelegram ? '1' : '0');
            formData.append('private', isPrivate ? '1' : '0');

            let headers = new Headers();
            let options: RequestInit = {
                method: 'POST',
                headers,
                body: formData
            }

            fetch('api/upload', options)
                .then(res => res)
                .then(_ => {
                    getFiles();
                })
                .catch(err => console.error(err));

        }
    }

    let onDelete = () => {
        if (isDelete) {
            // confirm
            let deleteList = (document.querySelectorAll('.todelete') as NodeList);
            let toDeleteList: string[] = [];
            deleteList.forEach((node: any) => {
                if (node.checked) {
                    toDeleteList.push(node.value);
                }
            });

            if (toDeleteList.length > 0) {
                let data = {
                    list: toDeleteList
                };

                let options: RequestInit = {
                    method: 'PUT',
                    body: JSON.stringify(data)
                }
                fetch('api/files/delete', options)
                    .then(res => res.json())
                    .then(data => {
                        if (data.status === 200) {
                            setIsDelete(false);
                            getFiles();
                        }
                    })
                    .catch(err => console.error(err));
            } else {
                setIsDelete(false);
            }
        } else {
            setIsDelete(true);
        }
    }

    let deselectAll = () => {
        let deleteList = (document.querySelectorAll('.todelete') as NodeList);

        if (deleteList.length > 0) {
            deleteList.forEach((node: any) => {
                if (node.checked) {
                    node.checked = false;
                }
            })
        }
        setDeleteListSize(0);
    }

    let updateDeleteListSize = () => {
        let deleteList = (document.querySelectorAll('.todelete') as NodeList);
        let checkedCount = 0;
        deleteList.forEach((node: any) => node.checked && checkedCount++);
        setDeleteListSize(checkedCount);
    }

    // getFiles();
    return (
        <>
        <Content className="card-upload">
            <h2>Upload</h2>
            {/* <div className="upload-form">
                <input ref={filesRef} type="file" multiple id="files" name="files"></input>
                <div onClick={() => setIsTelegram(!isTelegram)}>
                    <input type="checkbox" style={{ marginRight: '10px'}} checked={isTelegram} onChange={(e) => setIsTelegram(e.currentTarget.checked)} /><label>Telegram Notification</label>
                </div>
                <div onClick={() => setIsPrivate(!isPrivate)}>
                    <input type="checkbox" className="red" style={{ marginRight: '10px' }} checked={isPrivate} onChange={(e) => setIsPrivate(e.currentTarget.checked)} /><label>Private</label>
                </div>
            </div>
            <button className="btn" onClick={uploadFiles}>
                Upload
            </button> */}
            <form method="POST" encType="multipart/form-data" action="api/upload">
                <div className="upload-form">
                    <input type="file" multiple name="files"></input>
                    <div onClick={() => setIsTelegram(!isTelegram)} style={{ marginRight: '10px' }}>
                        <input name="telegram" type="checkbox" style={{ marginRight: '10px' }} checked={isTelegram} value={isTelegram ? '1' : '0'} onChange={(e) => setIsTelegram(e.currentTarget.checked)} /><label>Telegram Notification</label>
                    </div>
                    <div onClick={() => setIsPrivate(!isPrivate)}>
                        <input name="private" type="checkbox" className="red" style={{ marginRight: '10px' }} checked={isPrivate} value={isPrivate ? '1' : '0'} onChange={(e) => setIsPrivate(e.currentTarget.checked)} /><label>Private</label>
                    </div>
                </div>
                <button className="btn" type="submit">
                    Upload
                </button>
            </form>
            </Content>
            <Content>
                <div className="Flex-Between Files-Header sticky">
                    <h2>Files</h2>
                    <div className="Flex-Between">
                        <button onClick={onDelete} style={{ 'marginRight': '0.2rem' }}>
                            {
                                !isDelete ? 'Delete Files' : deleteListSize > 0 ? 'Confirm' : 'Cancel'
                            }
                        </button>
                        {
                            !isDelete && <button onClick={getFiles}>Refresh List</button>
                        }
                        {
                            isDelete && <button onClick={deselectAll}>Deselect All</button>
                        }

                    </div>
                </div>
                <Directory dirList={dirList} isDelete={isDelete} onDeleteChange={updateDeleteListSize}></Directory>
            </Content>
            <ReactModal
                isOpen={showDeleteModal}
                contentLabel="Minimal Modal Example"
            >
                <button onClick={() => setShowDeleteModal(false)}>Close Modal</button>
            </ReactModal>
        </>
    );
}

export default App;

// export class App extends React.Component {
//     // state: AppStates = {
//     //     dirList: [],
//     //     isDelete: false,
//     //     deleteListSize: 0
//     // }
//     state: { 
//         dirList: [],
//         isDelete: boolean,
//         deleteListSize: number,
//         showDeleteModal: boolean
//     } = {
//         dirList: [],
//         isDelete: false,
//         deleteListSize: 0,
//         showDeleteModal: false
//     }

//     constructor(props: any) {
//         super(props);
//         this.handleOpenModal = this.handleOpenModal.bind(this);
//         this.handleCloseModal = this.handleCloseModal.bind(this);
//     }

//     componentDidMount() {
//         this.getFiles();
//     }

//     handleOpenModal() {
//         this.setState({ showDeleteModal: true });
//     }

//     handleCloseModal() {
//         this.setState({ showDeleteModal: false });
//     }

//     getFiles() {
//         let options = {
//             method: 'GET'
//         }
//         fetch('api/files', options)
//             .then(res => res.json())
//             .then(data => {
//                 this.setState({ dirList: data })
//             })
//             .catch(err => console.error(err));
//     }

//     uploadFiles() {
//         let filesDom = ((document.getElementById('files') as HTMLInputElement).files as any);
        
//         if (filesDom.length > 0) {
//             let formData = new FormData();
//             for (let i = 0; i < filesDom.length; i++) {
//                 formData.append('files[]', filesDom.item(i));
//             }
    
//             let headers = new Headers();
//             headers.append('Content-Type', 'multipart/form-data');
//             let options = {
//                 method: 'POST',
//                 headers,
//                 body: formData
//             }

//             fetch('api/upload', options)
//                 .then(res => res)
//                 .then(data => {
//                     console.log(data);
//                     this.getFiles();
//                 })
//                 .catch(err => console.error(err));

//         }

//     }

//     onDelete() {
//         if (this.state.isDelete) {
//             // confirm
//             let deleteList = (document.querySelectorAll('.todelete') as NodeList);
//             let toDeleteList: string[] = [];
//             deleteList.forEach((node: any) => {
//                 if (node.checked) {
//                     toDeleteList.push(node.value);
//                 }
//             });

//             if (toDeleteList.length > 0) {

//             // this.handleOpenModal();
//                 let data = {
//                     list: toDeleteList
//                 };

//                 let options = {
//                     method: 'PUT',
//                     body: JSON.stringify(data)
//                 }
//                 fetch('api/files/delete', options)
//                     .then(res => res.json())
//                     .then(data => {
//                         if (data.status === 200) {
//                             this.setState({ isDelete: false })
//                             this.getFiles();
//                         }
//                     })
//                     .catch(err => console.error(err));
//             } else {
//                 this.setState({ isDelete: false });    
//             }
//         } else {
//             this.setState({isDelete: true});
//         }
//     }

//     onConfirmDelete() {

//     }

//     deselectAll() {
//         let deleteList = (document.querySelectorAll('.todelete') as NodeList);

//         if (deleteList.length > 0) {
//             deleteList.forEach((node: any) => {
//                 if (node.checked) {
//                     node.checked = false;
//                 }
//             })
//         }
//         this.setState({deleteListSize: 0});
//     }

//     updateDeleteListSize() {
//         let deleteList = (document.querySelectorAll('.todelete') as NodeList);
//         let checkedCount = 0;
//         deleteList.forEach((node: any) => node.checked && checkedCount++);
//         this.setState({deleteListSize: checkedCount});
//     }

//     render() {
//         return (
//             <>
//             <Content className="card-upload">
//                 <h2>Upload</h2>
//                 {/* <input type="file" multiple id="files" name="files"></input>
//                 <button onClick={this.uploadFiles.bind(this)}>
//                     Upload
//                 </button> */}
//                 <form method="POST" encType="multipart/form-data" action="api/upload">
//                     <input type="file" multiple name="files"></input>
//                     <br/>
//                     <button className="btn" type="submit">
//                         Upload
//                     </button>
//                 </form>
//             </Content>
//             <Content>
//                 <div className="Flex-Between Files-Header sticky">
//                     <h2>Files</h2>
//                     <div className="Flex-Between">
//                         <button onClick={ this.onDelete.bind(this) } style={{ 'marginRight': '0.2rem' }}>
//                             {
//                                 !this.state.isDelete ? 'Delete Files' : this.state.deleteListSize > 0 ? 'Confirm' : 'Cancel'
//                             }
//                         </button>
//                         {
//                             !this.state.isDelete && <button onClick={this.getFiles.bind(this)}>Refresh List</button>
//                         }
//                         {
//                             this.state.isDelete && <button onClick={this.deselectAll.bind(this)}>Deselect All</button>
//                         }
                        
//                     </div>
//                 </div>
//                 <Directory dirList={this.state.dirList} isDelete={this.state.isDelete} onDeleteChange={this.updateDeleteListSize.bind(this)}></Directory>
//             </Content>
//             <ReactModal
//                 isOpen={this.state.showDeleteModal}
//                 contentLabel="Minimal Modal Example"
//             >
//                 <button onClick={this.handleCloseModal}>Close Modal</button>
//             </ReactModal>
//             </>
//         )
//     }
// }